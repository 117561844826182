<template>
    <div class="ca-login">
        <div class="title">
            <i class="el-icon-lock"></i>
            使用CA登录
        </div>

        <div class="row">
            <my-select
                class="sel-ca-type"
                :data="calist"
                v-model="caType"
                size="medium"
            ></my-select>
        </div>

        <drag-verify
            :width="296"
            text="拖动滑块CA登录"
            @passcallback="submitHandler"
        ></drag-verify>
    </div>
</template>

<script>
import DragVerify from '@components/sys/drag-verify';

export default {
    components: {
        DragVerify,
    },
    data() {
        return {
            caUnify: null,

            randomString: '',    // 签名用随机数

            calist: [],
            caType: '',
        };
    },
    mounted() {},
    methods: {
        submitHandler() {
            if (!this.caType) {
                showMsgBox('请选择CA类型');
                return;
            }

            this.caUnify.mount(this.caType);

            var sData,
                sCert,
                sn,
                useTime;

            this.caUnify.initialization({}, next => {
                next();
            }).sign({
                character: this.randomString,
            }, (next, res) => {
                sData = res.signData;

                next();
            }).getCertB64({}, (next, res) => {
                sCert = res.signCert;

                next();
            }).getDeviceSN({}, (next, res) => {
                sn = res.sn;

                next();
            }).CAterm({}, (next, res) => {
                useTime = res.dYxq;

                this.$store.dispatch('userinfo/caLoginHandler', {
                    data: {
                        sData,
                        sCert,
                        useTime,
                        sn,
                        randomString: this.randomString,
                        caType: this.caType,
                    },
                    success: () => {
                        this.$emit('login');

                        this.$store.dispatch(
                            'userinfo/loginCallbackDispatch'
                        );
                        
                        this.$emit('getMenuAndGo');
                    },
                });
            }).run();
        },
        /**
         * 获取签名用的随机数
         */
        getRandom() {
            this.$get(`${this.$store.state.api.userUrl}/causerbind/carandom`, data => {
                this.randomString = data;
            });
        },
        excList(arr) {
            arr = arr.filter(item => /(hebei)|(jiantou)/.test(item.value));

            arr = arr.map(item => {
                return {
                    key: item.value,
                    value: item.label,
                };
            });

            return arr;
        },
    },
    mounted() {
        const CaUnify = require('@js/CaUnify.min.js');

        this.caUnify = new CaUnify();

        this.calist = this.excList(this.caUnify.$list);

        this.getRandom();
    },
};
</script>

<style lang='scss' scoped>
.ca-login {
    padding: 20px 0 20px;

    .title{
        font-size: 16px;
        font-weight: bold;
        color: #575d6c;
        margin-bottom: 1em;
    }

    .row{
        margin-bottom: 1em;

        .sel-ca-type{
            width: 100%;
        }
    }
}


</style>