<template>
    <client-only>
        <drag-verify
            ref="verify"
            class="my-drag-verify"
            :width="width"
            :height="40"
            :text="text"
            :success-text="successText"
            background="#e91b2b"
            progress-bar-bg="#52C41A"
            completed-bg="#52C41A"
            text-size="14"
            :circle="false"
            handlerIcon="el-icon-d-arrow-right"
            successIcon="el-icon-circle-check"
            @passcallback="passcallback"
            textColor="#fff"
        ></drag-verify>
    </client-only>
</template>

<script>
import DragVerify from './drag-verify-custom.vue';

export default {
    components: {
        DragVerify
    },
    props: {
        width: {
            type: Number,
            default: 296,
        },
        text: {
            type: String,
            default: '滑动滑块验证',
        },
        successText: {
            type: String,
            default: '验证成功',
        },
    },
    methods: {
        passcallback() {
            this.$emit('passcallback');
        },
        reset() {
            this.$refs.verify.reset();
        },
    },
};
</script>

<style lang="scss" scoped>
    .my-drag-verify{
        ::v-deep{
            &.drag_verify {
                .dv_handler {
                    border: solid 1px #e4e9f0;
                }

                .dv_text {
                    font-size: 14px;
                    font-weight: bolder;
                    color:#fff;
                    -webkit-text-fill-color:#fff !important;
                }
            }
        }
    }
</style>